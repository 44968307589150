<template>
  <div class="div">
    <template v-if="currentRole != 70 && currentRole != 60">
      <MainLayout>
        <template v-slot:navbar-title>Добавить</template>
        <CreateFormLayout>
          <template v-slot:title__text>
            Детская группа
          </template>
          <template v-slot:title-flex-right>
            <div></div>
          </template>
          <template>
            <!--    Данные компании    -->
            <div>
              <FormCardTitle title="Данные группы"></FormCardTitle>
              <FormInputBlock>
                <ValidationInputField
                    rules="required|number"
                    label="Номер детской группы"
                    v-model="number"
                    validate-name="номер детской группы"
                ></ValidationInputField>
                <ValidationInputField
                    rules="required"
                    label="Название группы"
                    v-model="name"
                    validate-name="название группы"
                ></ValidationInputField>

                <ValidationAutocompleteField
                    v-if="this.$store.getters.getCurrentRole !== 31"
                    :search-function="userGroupSearch"
                    rules="required"
                    label="Группа пользователей"
                    v-model="group"
                    validate-name="группа пользователей"
                />
              </FormInputBlock>
            </div>
            <!--    Данные компании    -->
          </template>
          <template v-slot:btns="{ handleSubmit }">
            <a @click="$router.go(-1)" class="btn btn_color_white">
              Отмена
            </a>
            <v-btn
                type="submit"
                @click="handleSubmit(createChildrenGroup)"
                class="btn btn-green"
            >
              Сохранить
            </v-btn>
          </template>
        </CreateFormLayout>
        <v-overlay :value="loading" z-index="9999">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </MainLayout>
    </template>
    <template v-else>
      <MainLayout>
        <template v-slot:navbar-title>
          Добавить
        </template>
        <CreateFormLayout>
          <template v-slot:title__text>
            Детская группа
          </template>
          <template v-slot:title-flex-right>
            <div></div>
          </template>

          <template>
            <FormCardTitle title="Данные группы"></FormCardTitle>
            <FormInputBlock>
              <ValidationInputField
                  rules="required|number"
                  label="Номер детской группы"
                  v-model="number"
                  validate-name="номер детской группы"
              ></ValidationInputField>
              <ValidationInputField
                  rules="required"
                  label="Название группы"
                  v-model="name"
                  validate-name="название группы"
              ></ValidationInputField>
              <ValidationInputField
                  rules="required"
                  label="Группа пользователей"
                  validate-name="группа пользователей"
                  :disabled = "true"
                  v-model="groupName"
              ></ValidationInputField>

            </FormInputBlock>
            <!--    Контроллеры группы     -->
            <div>
              <FormCardTitle>
                <p class="data__text">Контроллеры группы</p>
                <div>
                  <v-switch
                      class="switcher"
                      v-model="is_all"
                      label="Все контроллеры объекта"
                  />
                </div>
                <v-col v-if="!is_all && object">
                  <ValidationAutocompleteField
                      multiple
                      hide-selected
                      :search-function="controllerSearch"
                      label="Контроллер"
                      rules="required"
                      return-object
                      v-model="controllers"
                  >
                    <template v-slot:selection=""></template>
                  </ValidationAutocompleteField>
                </v-col>
              </FormCardTitle>
              <div style="margin-top: 15px;" v-if="is_all"></div>
              <BasicTable
                  v-if="!is_all"
                  :header="[
              { text: 'ID', value: 'id' },
              { text: 'Название', value: 'text' },
              {
                text: 'Удалить',
                width: 10,
                renderFunction: () => `<a class='form__btn-delete'></a>`
              }
            ]"
                  :table-data="controllers"
                  @row_click="deleteControllers($event.id)"
              ></BasicTable>
            </div>
            <!--    Контроллеры группы    -->

            <!--    Тариф группы пользователей     -->
            <div>
              <FormCardTitle class="data__text">
                Тариф группы пользователей
              </FormCardTitle>
              <FormInputBlock v-if="object.type === 'kindergarten'">
                <ValidationInputField
                    v-model="cardPrices"
                    rules="required|number|isEmpty"
                    validate-name="стоимость карты"
                    label="Стоимость карты"

                />
<!--                <ValidationInputField-->
<!--                    v-model="cardPrices"-->
<!--                    v-if="this.$store.getters.getCurrentRole == 60"-->
<!--                    rules="required|number|isEmpty"-->
<!--                    validate-name="сумма, которую получит Дилер, руб."-->
<!--                    label="Сумма, которую получит Дилер, руб."-->
<!--                />-->

                <ValidationSelectField
                    v-model="validity"
                    :items="[
                { text: '12', value: '12' },
                { text: '24', value: '24' },
                { text: '36', value: '36' },
                { text: '48', value: '48' },
                { text: '60 ', value: '60' }
              ]"
                    rules="required"
                    label="Срок действия, мес."
                    validate-name="срок действия"
                ></ValidationSelectField>
              </FormInputBlock>

              <FormInputBlock>
                <p>Стоимость тарифа для приложения</p>
                <v-spacer/>
                <v-spacer/>
                <ValidationInputField
                    rules="required"
                    v-model="tariff_name"
                    label="Название тарифа"
                    validate-name="название тарифа"
                />
                <v-spacer/>
                <v-spacer/>

                <ValidationInputField
                    rules="required|number|isEmpty"
                    v-model="app_price_3"
                    label="Стоимость за 3 месяца"
                    prefix="₽"
                />

<!--                <ValidationInputField-->
<!--                    v-if="this.$store.getters.getCurrentRole == 60"-->
<!--                    rules="required|number|isEmpty"-->
<!--                    v-model="app_price_3"-->
<!--                    validate-name="сумма, которую получит Дилер, руб."-->
<!--                    label="Сумма, которую получит Дилер, руб."-->
<!--                />-->
<!--                <v-spacer-->
<!--                    v-if="this.$store.getters.getCurrentRole == 60"-->
<!--                />-->

                <ValidationInputField
                    rules="required|number|isEmpty"
                    v-model="app_price_12"
                    label="Стоимость за 12 месяцев"
                    prefix="₽"
                />
<!--                <ValidationInputField-->
<!--                    v-if="this.$store.getters.getCurrentRole == 60"-->
<!--                    rules="required|number|isEmpty"-->
<!--                    v-model="app_price_3"-->
<!--                    validate-name="сумма, которую получит Дилер, руб."-->
<!--                    label="Сумма, которую получит Дилер, руб."-->
<!--                />-->
                <v-spacer/>
                <v-spacer/>
                <!--            <ValidationInputField-->
                <!--              rules="required|price"-->
                <!--              v-model="appPrice"-->
                <!--              prefix="₽"-->
                <!--              label="Стоимость за 1 день"-->
                <!--              :suffix="Math.ceil(appPrice * 30) + '₽'"-->
                <!--              validate-name="стоимость за 1 день"-->
                <!--            />-->
                <div class="" style="width: 100%;    display: grid;
    grid-template-columns: 2fr 2fr; grid-gap: 30px">

                </div>

              </FormInputBlock>
            </div>
            <!--    Тариф группы пользователей    -->
          </template>
          <template v-slot:btns="{ handleSubmit }">
            <a @click="$router.go(-1)" class="btn btn_color_white">
              Отмена
            </a>
            <v-btn
                type="submit"
                @click="handleSubmit(createChildrenGroup)"
                class="btn btn-green"
            >
              Сохранить
            </v-btn>
          </template>
        </CreateFormLayout>
        <v-overlay :value="loading" z-index="9999">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </MainLayout>
    </template>
  </div>

</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import CreateFormLayout from "@/components/layouts/CreateFormLayout";
import FormInputBlock from "@/components/createFormBlocks/FormInputBlock";
import ValidationInputField from "@/components/ValidationField/ValidationInputField";
import ValidationAutocompleteField from "@/components/ValidationField/ValidationAutocompleteField";
import FormCardTitle from "@/components/createFormBlocks/FormCardTitle";
import ValidationSelectField from "@/components/ValidationField/ValidationSelectField";

import {
  getObjectClearByIdRequest,
  getObjectsClearRequest
} from "@/helpers/api/objects";
import {getControllersRequest} from "@/helpers/api/controllers";
import BasicTable from "@/components/tables/BasicTable";
import {getKindergartenByIdRequest} from "@/helpers/api/kindergartens";
import { getAccessGroupsRequest } from "@/helpers/api/access-group";
import moment from "moment";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";

export default {
  components: {
    BasicTable,
    FormCardTitle,
    ValidationAutocompleteField,
    ValidationInputField,
    FormInputBlock,
    CreateFormLayout,
    MainLayout,
    ValidationSelectField
  },
  name: "CreateUserGroupPage",
  data() {
    return {
      loading: false,
      objectSearch: async value => {
        const organization =
            this.$store.getters.getCurrentRole === 31
                ? this.$store.getters.getCurrentOrganization
                : this.companyId
                    ? this.companyId
                    : null;
        return (
            await getObjectsClearRequest({
              query: {
                search: value,
                limit: 10000,
                organization: organization
              }
            })
        ).data.results.map(el => ({...el, text: el.name, value: el}));
      },
      userGroupSearch: async value => {
        return (
            await getAccessGroupsRequest({
              query: {
                organization: this.kindergartenId || this.currentOgr,
                search: value
              }
            })
        ).data.results
            .filter(
                el =>
                    el.title.includes(
                        "Пользователи с бесплатным доступом к объекту"
                    ) != true
            )
            .map(el => ({
              text: el.title,
              value: el.id
            }));
      },

      controllerSearch: async value => {
        let object = this.object ? {object: this.object.id} : {};
        return (
            await getControllersRequest({query: {search: value, ...object}})
        ).data.results.map(el => ({...el, text: el.name, value: el.id}));
      }
    };
  },

  props: {
    companyId: {
      type: [String, Number]
    },
    objectId: {
      type: [String, Number]
    },
    kindergartenId: {
      type: [String, Number]
    }
  },

  computed: {
    currentRole() {
      return this.$store.getters.getCurrentRole;
    },
    urlQuery() {
      return {
        id: this.$store.getters.getCurrentOrganization,
        query: this.$route.query
      };
    },

    number: {
      get() {
        return this.$store.getters.getChildrenGroupsCreateForm.number;
      },
      set(newValue) {
        this.$store.commit("setChildrenGroupsCreateForm", {
          fieldName: "number",
          value: newValue
        });
      }
    },
    group: {
      get() {
        return this.$store.getters.getChildrenGroupsCreateForm.group;
      },
      set(newValue) {
        this.$store.commit("setChildrenGroupsCreateForm", {
          fieldName: "group",
          value: newValue
        });
      }
    },
    groupName: {
      get() {
        return this.$store.getters.getChildrenGroupsCreateForm.number ? `Опекуны группы ${this.$store.getters.getChildrenGroupsCreateForm.number}` : "";
      },
      set(newValue) {
        this.$store.commit("setChildrenGroupsCreateForm", {
          fieldName: "group",
          value: newValue
        });
      }
    },
    name: {
      get() {
        return this.$store.getters.getChildrenGroupsCreateForm.name;
      },
      set(newValue) {
        this.$store.commit("setChildrenGroupsCreateForm", {
          fieldName: "name",
          value: newValue
        });
      }
    },
    threeDayCount: {
      get() {
        return Math.abs(moment().diff(moment().add(3, "month"), "day"));
      }
    },
    twelveDayCount: {
      get() {
        return Math.abs(moment().diff(moment().add(12, "month"), "day"));
      }
    },
    isMonday: {
      get() {
        return this.$store.getters.getCreateUserGroupForm.isMonday;
      },
      set(newValue) {
        this.$store.commit("setCreateUserGroupForm", {
          fieldName: "isMonday",
          value: newValue
        });
      }
    },
    isTuesday: {
      get() {
        return this.$store.getters.getCreateUserGroupForm.isTuesday;
      },
      set(newValue) {
        this.$store.commit("setCreateUserGroupForm", {
          fieldName: "isTuesday",
          value: newValue
        });
      }
    },
    isWednesday: {
      get() {
        return this.$store.getters.getCreateUserGroupForm.isWednesday;
      },
      set(newValue) {
        this.$store.commit("setCreateUserGroupForm", {
          fieldName: "isWednesday",
          value: newValue
        });
      }
    },
    isThursday: {
      get() {
        return this.$store.getters.getCreateUserGroupForm.isThursday;
      },
      set(newValue) {
        this.$store.commit("setCreateUserGroupForm", {
          fieldName: "isThursday",
          value: newValue
        });
      }
    },
    isFriday: {
      get() {
        return this.$store.getters.getCreateUserGroupForm.isFriday;
      },
      set(newValue) {
        this.$store.commit("setCreateUserGroupForm", {
          fieldName: "isFriday",
          value: newValue
        });
      }
    },
    isSaturday: {
      get() {
        return this.$store.getters.getCreateUserGroupForm.isSaturday;
      },
      set(newValue) {
        this.$store.commit("setCreateUserGroupForm", {
          fieldName: "isSaturday",
          value: newValue
        });
      }
    },
    isSunday: {
      get() {
        return this.$store.getters.getCreateUserGroupForm.isSunday;
      },
      set(newValue) {
        this.$store.commit("setCreateUserGroupForm", {
          fieldName: "isSunday",
          value: newValue
        });
      }
    },
    startMonday: {
      get() {
        return this.$store.getters.getCreateUserGroupForm.startMonday;
      },
      set(newValue) {
        this.$store.commit("setCreateUserGroupForm", {
          fieldName: "startMonday",
          value: newValue
        });
      }
    },
    startTuesday: {
      get() {
        return this.$store.getters.getCreateUserGroupForm.startTuesday;
      },
      set(newValue) {
        this.$store.commit("setCreateUserGroupForm", {
          fieldName: "startTuesday",
          value: newValue
        });
      }
    },
    startWednesday: {
      get() {
        return this.$store.getters.getCreateUserGroupForm.startWednesday;
      },
      set(newValue) {
        this.$store.commit("setCreateUserGroupForm", {
          fieldName: "startWednesday",
          value: newValue
        });
      }
    },
    startThursday: {
      get() {
        return this.$store.getters.getCreateUserGroupForm.startThursday;
      },
      set(newValue) {
        this.$store.commit("setCreateUserGroupForm", {
          fieldName: "startThursday",
          value: newValue
        });
      }
    },
    startFriday: {
      get() {
        return this.$store.getters.getCreateUserGroupForm.startFriday;
      },
      set(newValue) {
        this.$store.commit("setCreateUserGroupForm", {
          fieldName: "startFriday",
          value: newValue
        });
      }
    },
    startSaturday: {
      get() {
        return this.$store.getters.getCreateUserGroupForm.startSaturday;
      },
      set(newValue) {
        this.$store.commit("setCreateUserGroupForm", {
          fieldName: "startSaturday",
          value: newValue
        });
      }
    },
    startSunday: {
      get() {
        return this.$store.getters.getCreateUserGroupForm.startSunday;
      },
      set(newValue) {
        this.$store.commit("setCreateUserGroupForm", {
          fieldName: "startSunday",
          value: newValue
        });
      }
    },
    endMonday: {
      get() {
        return this.$store.getters.getCreateUserGroupForm.endMonday;
      },
      set(newValue) {
        this.$store.commit("setCreateUserGroupForm", {
          fieldName: "endMonday",
          value: newValue
        });
      }
    },
    endTuesday: {
      get() {
        return this.$store.getters.getCreateUserGroupForm.endTuesday;
      },
      set(newValue) {
        this.$store.commit("setCreateUserGroupForm", {
          fieldName: "endTuesday",
          value: newValue
        });
      }
    },
    endWednesday: {
      get() {
        return this.$store.getters.getCreateUserGroupForm.endWednesday;
      },
      set(newValue) {
        this.$store.commit("setCreateUserGroupForm", {
          fieldName: "endWednesday",
          value: newValue
        });
      }
    },
    endThursday: {
      get() {
        return this.$store.getters.getCreateUserGroupForm.endThursday;
      },
      set(newValue) {
        this.$store.commit("setCreateUserGroupForm", {
          fieldName: "endThursday",
          value: newValue
        });
      }
    },
    endFriday: {
      get() {
        return this.$store.getters.getCreateUserGroupForm.endFriday;
      },
      set(newValue) {
        this.$store.commit("setCreateUserGroupForm", {
          fieldName: "endFriday",
          value: newValue
        });
      }
    },
    endSaturday: {
      get() {
        return this.$store.getters.getCreateUserGroupForm.endSaturday;
      },
      set(newValue) {
        this.$store.commit("setCreateUserGroupForm", {
          fieldName: "endSaturday",
          value: newValue
        });
      }
    },
    endSunday: {
      get() {
        return this.$store.getters.getCreateUserGroupForm.endSunday;
      },
      set(newValue) {
        this.$store.commit("setCreateUserGroupForm", {
          fieldName: "endSunday",
          value: newValue
        });
      }
    },
    startTime: {
      get() {
        return this.$store.getters.getCreateUserGroupForm.startTime;
      },
      set(newValue) {
        this.$store.commit("setCreateUserGroupForm", {
          fieldName: "startTime",
          value: newValue
        });
      }
    },
    endTime: {
      get() {
        return this.$store.getters.getCreateUserGroupForm.endTime;
      },
      set(newValue) {
        this.$store.commit("setCreateUserGroupForm", {
          fieldName: "endTime",
          value: newValue
        });
      }
    },
    is_all: {
      get() {
        return this.$store.getters.getCreateUserGroupForm.is_all;
      },
      set(newValue) {
        this.$store.commit("setCreateUserGroupForm", {
          fieldName: "is_all",
          value: newValue
        });
      }
    },
    title: {
      get() {
        return this.$store.getters.getCreateUserGroupForm.title;
      },
      set(newValue) {
        this.$store.commit("setCreateUserGroupForm", {
          fieldName: "title",
          value: newValue
        });
      }
    },
    tariff_name: {
      get() {
        return this.$store.getters.getCreateUserGroupForm.tariff_name;
      },
      set(newValue) {
        this.$store.commit("setCreateUserGroupForm", {
          fieldName: "tariff_name",
          value: newValue
        });
      }
    },
    timeType: {
      get() {
        return this.$store.getters.getCreateUserGroupForm.timeType;
      },
      set(newValue) {
        this.$store.commit("setCreateUserGroupForm", {
          fieldName: "timeType",
          value: newValue
        });
      }
    },
    organization: {
      get() {
        return this.$store.getters.getCreateUserGroupForm.organization;
      },
      set(newValue) {
        this.$store.commit("setCreateUserGroupForm", {
          fieldName: "organization",
          value: newValue
        });
      }
    },
    object: {
      get() {
        return this.$store.getters.getCreateUserGroupForm.object;
      },
      set(newValue) {
        this.$store.commit("setCreateUserGroupForm", {
          fieldName: "object",
          value: newValue
        });
      }
    },
    // appPrice: {
    //   get() {
    //     return this.$store.getters.getCreateUserGroupForm.appPrice;
    //   },
    //   set(newValue) {
    //     this.$store.commit("setCreateUserGroupForm", {
    //       fieldName: "appPrice",
    //       value: newValue
    //     });
    //   }
    // },
    controllers: {
      get() {
        return this.$store.getters.getCreateUserGroupForm.controllers;
      },
      set(newValue) {
        this.$store.commit("setCreateUserGroupForm", {
          fieldName: "controllers",
          value: newValue
        });
      }
    },
    cardPrices: {
      get() {
        let str = this.$store.getters.getCreateUserGroupForm.cardPrices?.replace(
            /^0+/,
            ""
        );
        return str;
      },
      set(newValue) {
        this.$store.commit("setCreateUserGroupForm", {
          fieldName: "cardPrices",
          value: newValue
        });
      }
    },
    validity: {
      get() {
        return this.$store.getters.getCreateUserGroupForm.duration;
      },
      set(newValue) {
        this.$store.commit("setCreateUserGroupForm", {
          fieldName: "duration",
          value: newValue
        });
      }
    },
    app_price_3: {
      get() {
        let str = this.$store.getters.getCreateUserGroupForm.app_price_3?.replace(
            /^0+/,
            ""
        );
        return str;
      },
      set(newValue) {
        this.$store.commit("setCreateUserGroupForm", {
          fieldName: "app_price_3",
          value: newValue
        });
      }
    },
    app_price_12: {
      get() {
        let str = this.$store.getters.getCreateUserGroupForm.app_price_12?.replace(
            /^0+/,
            ""
        );
        return str;
      },
      set(newValue) {
        this.$store.commit("setCreateUserGroupForm", {
          fieldName: "app_price_12",
          value: newValue
        });
      }
    }
  },

  created() {
    this.$store.commit("setSearchQuery", {
      id: this.urlQuery.id,
      query: this.urlQuery.query
    });
    this.isDateLoaded = true;
    // if (this.companyId) {
    //   this.object = Number(this.companyId);
    // }
    if (this.objectId) {
      this.preSelectObject(this.objectId);
    }
    if (this.$store.getters.getCurrentRole === 31) {
      getKindergartenByIdRequest({
        id: this.$store.getters.getCurrentOrganization
      }).then(async response => {
        this.object = (
            await getObjectClearByIdRequest({
              id: response.data.object.id
            })
        ).data;
      });
    }
    if (this.kindergartenId) {
      getKindergartenByIdRequest({id: this.kindergartenId}).then(
          async response => {
            this.object = (
                await getObjectClearByIdRequest({
                  id: response.data.object.id
                })
            ).data;
          }
      );
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit("clearCreateUserGroupForm");
    this.$store.commit("clearCreateChildrenGroupForm");
    next();
  },
  methods: {
    async preSelectObject(id) {
      this.object = (await getObjectClearByIdRequest({id})).data;
    },
    // createUserGroup() {
    //   this.loading = true;
    //   this.$store
    //       .dispatch("createUserGroup")
    //       .then((response) => {
    //         console.log(response[0].data)
    //         localStorage.setItem("UserGroup", response[0].data.group)
    //         this.$store.dispatch(
    //             "successNotification",
    //             actionMessage.add(successMessageDictionary.userGroup)
    //         );
    //         this.createChildrenGroup()
    //       })
    //       .finally(() => (this.loading = false));
    // },
    createChildrenGroup() {
      this.loading = true;

      if (!this.group) {
        this.loading = true;
        this.$store
            .dispatch("fetchOrganizationObjectsList") // вызываем чтобы получить id объекта ДС
            .then(res => {
              const orgName = this.$store.getters.getDealerInfo.results?.name;

              this.objectId =
                  res.results.filter(el => el?.name == orgName)[0]?.id ||
                  res.results[0].id;
            });
        if(this.currentRole == 60 || this.currentRole == 70){
          this.$store
              .dispatch("createUserGroup")
              .then(() => {
                this.$store
                    .dispatch("createChildrenGroupsFromChildren")
                    .then(() => {
                      this.$store.dispatch(
                          "successNotification",
                          actionMessage.add(successMessageDictionary.childrenGroup)
                      );
                    })
                    .finally(() => {
                      this.loading = false;
                    });
              })
              .finally(() => (this.loading = false));
        }else {
          this.$store
              .dispatch("createUserGroupFromChildren")
              .then(() => {

                this.$store
                    .dispatch("createChildrenGroupsFromChildren")
                    .then(() => {
                      this.$store.dispatch(
                          "successNotification",
                          actionMessage.add(successMessageDictionary.childrenGroup)
                      );
                    })
                    .finally(() => {
                      this.loading = false;
                    });
              })
              .finally(() => (this.loading = false));
        }

      } else {

        this.$store
            .dispatch("createChildrenGroups")
            .then(() => {
              this.$store.dispatch(
                  "successNotification",
                  actionMessage.add(successMessageDictionary.childrenGroup)
              );
            })
            .finally(() => {
              this.loading = false;
              localStorage.removeItem("UserGroup");
              localStorage.removeItem("UserGroupName");
              localStorage.removeItem("UserGroupNumber");
            });
      }
    },

    deleteControllers(id) {
      this.controllers = this.controllers.filter(el => el.id !== id);
    }
  }
};
</script>

<style scoped></style>
